import { createAction, props } from '@ngrx/store'

import { ISnackBar } from '~/ui/snack-bar'

import { ELanguage } from './ui.model'
import { uiFeatureKey } from './ui.state'

export const initUi = createAction(`[${uiFeatureKey}] Init UI`)

export const changeThemeMode = createAction(`[${uiFeatureKey}] Change theme mode`, props<{ isDarkTheme: boolean }>())

export const saveThemeMode = createAction(`[${uiFeatureKey}] Save theme mode`, props<{ isDarkTheme: boolean }>())

export const startRequest = createAction(`[${uiFeatureKey}] Start request`)

export const endRequest = createAction(`[${uiFeatureKey}] End request`)

export const changeLanguage = createAction(`[${uiFeatureKey}] Change language`)

export const saveLanguage = createAction(`[${uiFeatureKey}] Save language`, props<{ language: ELanguage }>())

export const addNotification = createAction(`[${uiFeatureKey}] Add Notification`, props<{ snackBarData: ISnackBar }>())

export const addErrorNotification = createAction(
  `[${uiFeatureKey}] Add Error Notification`,
  props<{
    messageI18n: string
    messageKeyI18n?: { [key: string]: string }
  }>()
)

export const addInfoNotification = createAction(
  `[${uiFeatureKey}] Add Info Notification`,
  props<{
    messageI18n: string
    messageKeyI18n?: { [key: string]: string }
  }>()
)
