import { Injectable, InjectionToken } from '@angular/core'

import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'

import {
  IBanUserDto,
  ICreateUserDto,
  IGuardPlanSection,
  IPlanDto,
  IRoleDto,
  ISearchPaginationData,
  IUpdateUserDto,
  IUserDto,
} from '~/core/models'

import * as ADMIN_ACTIONS from './admin.actions'
import {
  selectAdminInErrorRole,
  selectAdminInRequestRole,
  selectAdminRolesError,
  selectAdminRolesList,
  selectAdminUserInError,
  selectAdminUserInRequest,
  selectAdminUserList,
  selectAdminUserSearchPaginationData,
  selectAdminUsersError,
  selectGuardError,
  selectGuardInError,
  selectGuardInRequest,
  selectGuardPlanList,
  selectGuardPlanSectionList,
  selectSelectedUser,
} from './admin.selectors'
import { IAppWithAdminState } from './admin.state'

export interface IAdminFacade {
  userList$: Observable<IUserDto[]>
  selectedUser$: Observable<IUserDto | null>
  searchUserPaginationData$: Observable<ISearchPaginationData>
  inRequestUser$: Observable<boolean>
  inErrorUser$: Observable<boolean>
  userError$: Observable<Error | null | undefined>

  inRequestRole$: Observable<boolean>
  inErrorRole$: Observable<boolean>
  roleList$: Observable<IRoleDto[]>
  roleError$: Observable<Error | null | undefined>

  inRequestGuardPlan$: Observable<boolean>
  inErrorGuardPlan$: Observable<boolean>
  guardPlanList$: Observable<IPlanDto[]>
  guardPlanSectionList$: Observable<IGuardPlanSection[]>
  guardPlanError$: Observable<Error | null | undefined>

  getUserList(): void
  changeSearchUserData(searchData: ISearchPaginationData): void
  selectUser(selectedUser: IUserDto | null): void
  createUser(userData: ICreateUserDto): void
  updateUser(userData: IUpdateUserDto): void
  banUser(userData: IBanUserDto): void
  unbanUser(userId: number): void
  deleteUser(userId: number): void
  addApiKey(userId: number): void
  deleteApiKey(userId: number, apiKeyId: number): void
  getRoleList(): void
  addRole(name: string): void
}

export const ADMIN_FACADE = new InjectionToken<IAdminFacade>('ADMIN_FACADE')

@Injectable()
export class AdminFacade implements IAdminFacade {
  userList$ = this.store$.select(selectAdminUserList)
  selectedUser$ = this.store$.select(selectSelectedUser)
  searchUserPaginationData$ = this.store$.select(selectAdminUserSearchPaginationData)
  inRequestUser$ = this.store$.select(selectAdminUserInRequest)
  inErrorUser$ = this.store$.select(selectAdminUserInError)
  userError$ = this.store$.select(selectAdminUsersError)

  inRequestRole$ = this.store$.select(selectAdminInRequestRole)
  inErrorRole$ = this.store$.select(selectAdminInErrorRole)
  roleList$ = this.store$.select(selectAdminRolesList)
  roleError$ = this.store$.select(selectAdminRolesError)

  inRequestGuardPlan$ = this.store$.select(selectGuardInRequest)
  inErrorGuardPlan$ = this.store$.select(selectGuardInError)
  guardPlanList$ = this.store$.select(selectGuardPlanList)
  guardPlanSectionList$ = this.store$.select(selectGuardPlanSectionList)
  guardPlanError$ = this.store$.select(selectGuardError)

  constructor(private store$: Store<IAppWithAdminState>) {}

  // User

  getUserList(): void {
    this.store$.dispatch(ADMIN_ACTIONS.getUserList())
  }

  changeSearchUserData(searchData: ISearchPaginationData): void {
    this.store$.dispatch(ADMIN_ACTIONS.changeSearchUserData({ searchData }))
  }

  selectUser(selectedUser: IUserDto | null): void {
    this.store$.dispatch(ADMIN_ACTIONS.selectUser({ selectedUser }))
  }

  createUser(userData: ICreateUserDto): void {
    this.store$.dispatch(ADMIN_ACTIONS.createUser({ userData }))
  }

  updateUser(userData: IUpdateUserDto): void {
    this.store$.dispatch(ADMIN_ACTIONS.updateUser({ userData }))
  }

  banUser(userData: IBanUserDto): void {
    this.store$.dispatch(ADMIN_ACTIONS.banUser({ userData }))
  }

  unbanUser(userId: number): void {
    this.store$.dispatch(ADMIN_ACTIONS.unbanUser({ userId }))
  }

  deleteUser(userId: number): void {
    this.store$.dispatch(ADMIN_ACTIONS.deleteUser({ userId }))
  }

  addApiKey(userId: number): void {
    this.store$.dispatch(ADMIN_ACTIONS.addApiKey({ userId }))
  }

  deleteApiKey(userId: number, apiKeyId: number): void {
    this.store$.dispatch(ADMIN_ACTIONS.deleteApiKey({ userId, apiKeyIds: [apiKeyId] }))
  }

  // Role

  getRoleList(): void {
    this.store$.dispatch(ADMIN_ACTIONS.getRoleList())
  }

  addRole(name: string): void {
    this.store$.dispatch(ADMIN_ACTIONS.addRole({ name }))
  }

  // Guard Plan

  getGuardPlanList(): void {
    this.store$.dispatch(ADMIN_ACTIONS.getGuardPlanList())
  }

  getGuardPlanSectionList(): void {
    this.store$.dispatch(ADMIN_ACTIONS.getGuardPlanSectionList())
  }
}
