import { createAction, props } from '@ngrx/store'

import {
  IApiKeyDto,
  IBanUserDto,
  ICreateUserDto,
  IGuardPlanSection,
  IPlanDto,
  IResponsePaginationApiDto,
  IRoleDto,
  ISearchPaginationData,
  IUpdateUserDto,
  IUserDto,
} from '~/core/models'

import { adminFeatureKey } from './admin.state'

// User

export const getUserList = createAction(`[${adminFeatureKey}] Get User List`)

export const getUserListSuccess = createAction(
  `[${adminFeatureKey}] Get User List Success`,
  props<{ userData: IResponsePaginationApiDto<IUserDto> }>()
)

export const getUserListError = createAction(
  `[${adminFeatureKey}] Get Guard Plan List Error`,
  props<{ error: Error }>()
)

export const changeSearchUserData = createAction(
  `[${adminFeatureKey}] Change User Search Data`,
  props<{ searchData: ISearchPaginationData }>()
)

export const selectUser = createAction(`[${adminFeatureKey}] Select User`, props<{ selectedUser: IUserDto | null }>())

export const updateUser = createAction(`[${adminFeatureKey}] Update User`, props<{ userData: IUpdateUserDto }>())

export const updateUserSuccess = createAction(`[${adminFeatureKey}] Update User Success`, props<{ user: IUserDto }>())

export const updateUserError = createAction(`[${adminFeatureKey}] Update User Error`, props<{ error: Error }>())

export const createUser = createAction(`[${adminFeatureKey}] Create User`, props<{ userData: ICreateUserDto }>())

export const createUserSuccess = createAction(`[${adminFeatureKey}] Create User Success`, props<{ user: IUserDto }>())

export const createUserError = createAction(`[${adminFeatureKey}] Create User Error`, props<{ error: Error }>())

export const banUser = createAction(`[${adminFeatureKey}] Ban User`, props<{ userData: IBanUserDto }>())

export const banUserSuccess = createAction(`[${adminFeatureKey}] Ban User Success`, props<{ user: IUserDto }>())

export const banUserError = createAction(`[${adminFeatureKey}] Ban User Error`, props<{ error: Error }>())

export const unbanUser = createAction(`[${adminFeatureKey}] Unban User`, props<{ userId: number }>())

export const unbanUserSuccess = createAction(`[${adminFeatureKey}] Unban User Success`, props<{ user: IUserDto }>())

export const unbanUserError = createAction(`[${adminFeatureKey}] Unban User Error`, props<{ error: Error }>())

export const deleteUser = createAction(`[${adminFeatureKey}] Delete User`, props<{ userId: number }>())

export const deleteUserSuccess = createAction(`[${adminFeatureKey}] Delete User Success`, props<{ userId: number }>())

export const deleteUserError = createAction(`[${adminFeatureKey}] Delete User Error`, props<{ error: Error }>())

export const addApiKey = createAction(`[${adminFeatureKey}] Add Api Key`, props<{ userId: number }>())

export const addApiKeySuccess = createAction(
  `[${adminFeatureKey}] Add Api Key Success`,
  props<{ userId: number; apiKeys: IApiKeyDto[] }>()
)

export const addApiKeyError = createAction(`[${adminFeatureKey}] Add Api Key Error`, props<{ error: Error }>())

export const deleteApiKey = createAction(
  `[${adminFeatureKey}] Delete Api Key`,
  props<{ userId: number; apiKeyIds: number[] }>()
)

export const deleteApiKeySuccess = createAction(
  `[${adminFeatureKey}] Delete Api Key Success`,
  props<{ userId: number; apiKeys: IApiKeyDto[] }>()
)

export const deleteApiKeyError = createAction(`[${adminFeatureKey}] Delete Api Key Error`, props<{ error: Error }>())

// Role

export const getRoleList = createAction(`[${adminFeatureKey}] Get Role List`)

export const getRoleListSuccess = createAction(
  `[${adminFeatureKey}] Get Role List Success`,
  props<{ roles: IRoleDto[] }>()
)

export const getRoleListError = createAction(`[${adminFeatureKey}] Get Role List Error`, props<{ error: Error }>())

export const addRole = createAction(`[${adminFeatureKey}] Add Role`, props<{ name: string }>())

export const addRoleSuccess = createAction(`[${adminFeatureKey}] Add Role Success`, props<{ role: IRoleDto }>())

export const addRoleError = createAction(`[${adminFeatureKey}] Add Role Error`, props<{ error: Error }>())

// Guard Plan

export const getGuardPlanList = createAction(`[${adminFeatureKey}] Get Guard Plan List`)

export const getGuardPlanListSuccess = createAction(
  `[${adminFeatureKey}] Get Guard Plan List Success`,
  props<{ guardPlanList: IPlanDto[] }>()
)

export const getGuardPlanListError = createAction(
  `[${adminFeatureKey}] Get Guard Plan List Error`,
  props<{ error: Error }>()
)

export const getGuardPlanSectionList = createAction(`[${adminFeatureKey}] Get Guard Plan Section List`)

export const getGuardPlanSectionListSuccess = createAction(
  `[${adminFeatureKey}] Get Guard Plan Section List Success`,
  props<{ guardPlanSectionList: IGuardPlanSection[] }>()
)

export const getGuardPlanSectionListError = createAction(
  `[${adminFeatureKey}] Get Guard Plan Section List Error`,
  props<{ error: Error }>()
)
