import { NgModule } from '@angular/core'

import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'

import { AdminEffects } from './admin.effects'
import { ADMIN_FACADE, AdminFacade } from './admin.facade'
import { adminReducer } from './admin.reducer'
import { adminFeatureKey } from './admin.state'

const EFFECTS = [AdminEffects]

@NgModule({
  imports: [StoreModule.forFeature(adminFeatureKey, adminReducer), EffectsModule.forFeature(EFFECTS)],
  providers: [...EFFECTS, { provide: ADMIN_FACADE, useClass: AdminFacade }],
})
export class AdminFeatureModule {}
