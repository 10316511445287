import { APP_DEFAULT_LANGUAGE, ELanguage } from './ui.model'

export const uiFeatureKey = 'UI prox'

export interface IUiState {
  inRequestQueue: number
  isDarkTheme: boolean
  language: ELanguage
}

export const createUiInitState = (): IUiState => ({
  isDarkTheme: false,
  inRequestQueue: 0,
  language: APP_DEFAULT_LANGUAGE,
})

export interface IAppWithUiState {
  ui: IUiState
}

export const createAppWithUiInitState = (): IAppWithUiState => ({
  ui: createUiInitState(),
})
