import { Action, createReducer, on } from '@ngrx/store'

import { IUserDto } from '~/core/models'

import * as ADMIN_ACTIONS from './admin.actions'
import { createAdminInitState, IAdminState } from './admin.state'

export const adminState = createReducer(
  createAdminInitState(),

  // User

  on(ADMIN_ACTIONS.getUserList, state => ({
    ...state,
    data: { ...state.data, user: { ...state.data.user, inRequest: true } },
  })),
  on(ADMIN_ACTIONS.getUserListSuccess, (state, { userData }) => ({
    ...state,
    data: {
      ...state.data,
      user: {
        ...state.data.user,
        inRequest: false,
        inError: false,
        userList: userData.data,
        searchPaginationData: {
          ...state.data.user.searchPaginationData,
          paginatorData: userData.pagination,
        },
      },
      userError: null,
    },
  })),
  on(ADMIN_ACTIONS.getUserListError, (state, { error }) => ({
    ...state,
    data: { ...state.data, user: { ...state.data.user, userList: [], inRequest: false, inError: true, error } },
  })),

  on(ADMIN_ACTIONS.updateUser, state => ({
    ...state,
    data: { ...state.data, user: { ...state.data.user, inRequest: true } },
  })),
  on(ADMIN_ACTIONS.updateUserSuccess, (state, { user }) => ({
    ...state,
    data: {
      ...state.data,
      user: {
        ...state.data.user,
        inRequest: false,
        inError: false,
        selectedUser: user,
        userList: state.data.user.userList.map(u => (u.id === user.id ? user : u)),
      },
      userError: null,
    },
  })),
  on(ADMIN_ACTIONS.updateUserError, (state, { error }) => ({
    ...state,
    data: { ...state.data, user: { ...state.data.user, inRequest: false, inError: true, error } },
  })),

  on(ADMIN_ACTIONS.createUser, state => ({
    ...state,
    data: { ...state.data, user: { ...state.data.user, inRequest: true } },
  })),
  on(ADMIN_ACTIONS.createUserSuccess, (state, { user }) => ({
    ...state,
    data: {
      ...state.data,
      user: {
        ...state.data.user,
        inRequest: false,
        inError: false,
        selectedUser: user,
        userList: [user, ...state.data.user.userList],
      },
      userError: null,
    },
  })),
  on(ADMIN_ACTIONS.createUserError, (state, { error }) => ({
    ...state,
    data: { ...state.data, user: { ...state.data.user, inRequest: false, inError: true, error } },
  })),

  on(ADMIN_ACTIONS.banUser, state => ({
    ...state,
    data: { ...state.data, user: { ...state.data.user, inRequest: true } },
  })),
  on(ADMIN_ACTIONS.banUserSuccess, (state, { user }) => ({
    ...state,
    data: {
      ...state.data,
      user: {
        ...state.data.user,
        inRequest: false,
        inError: false,
        selectedUser: user,
        userList: state.data.user.userList.map(u => (u.id === user.id ? user : u)),
      },
      userError: null,
    },
  })),
  on(ADMIN_ACTIONS.banUserError, (state, { error }) => ({
    ...state,
    data: { ...state.data, user: { ...state.data.user, inRequest: false, inError: true, error } },
  })),

  on(ADMIN_ACTIONS.unbanUser, state => ({
    ...state,
    data: { ...state.data, user: { ...state.data.user, inRequest: true } },
  })),
  on(ADMIN_ACTIONS.unbanUserSuccess, (state, { user }) => ({
    ...state,
    data: {
      ...state.data,
      user: {
        ...state.data.user,
        inRequest: false,
        inError: false,
        selectedUser: user,
        userList: state.data.user.userList.map(u => (u.id === user.id ? user : u)),
      },
      userError: null,
    },
  })),
  on(ADMIN_ACTIONS.unbanUserError, (state, { error }) => ({
    ...state,
    data: { ...state.data, user: { ...state.data.user, inRequest: false, inError: true, error } },
  })),

  on(ADMIN_ACTIONS.deleteUser, state => ({
    ...state,
    data: { ...state.data, user: { ...state.data.user, inRequest: true } },
  })),
  on(ADMIN_ACTIONS.deleteUserSuccess, (state, { userId }) => ({
    ...state,
    data: {
      ...state.data,
      user: {
        ...state.data.user,
        inRequest: false,
        inError: false,
        selectedUser: state.data.user.selectedUser?.id === userId ? null : state.data.user.selectedUser,
        userList: state.data.user.userList.filter(u => u.id !== userId),
      },
      userError: null,
    },
  })),
  on(ADMIN_ACTIONS.deleteUserError, (state, { error }) => ({
    ...state,
    data: { ...state.data, user: { ...state.data.user, inRequest: false, inError: true, error } },
  })),

  on(ADMIN_ACTIONS.addApiKey, state => ({
    ...state,
    data: { ...state.data, user: { ...state.data.user, inRequest: true } },
  })),
  on(ADMIN_ACTIONS.addApiKeySuccess, (state, { userId, apiKeys }) => {
    const currentUser = state.data.user.userList.find(user => user.id === userId)!
    const user: IUserDto = { ...currentUser, apiKeys }

    return {
      ...state,
      data: {
        ...state.data,
        user: {
          ...state.data.user,
          inRequest: false,
          inError: false,
          selectedUser: user,
          userList: state.data.user.userList.map(u => (u.id === user.id ? user : u)),
        },
        userError: null,
      },
    }
  }),
  on(ADMIN_ACTIONS.addApiKeyError, (state, { error }) => ({
    ...state,
    data: { ...state.data, user: { ...state.data.user, inRequest: false, inError: true, error } },
  })),

  on(ADMIN_ACTIONS.deleteApiKey, state => ({
    ...state,
    data: { ...state.data, user: { ...state.data.user, inRequest: true } },
  })),
  on(ADMIN_ACTIONS.deleteApiKeySuccess, (state, { userId, apiKeys }) => {
    const currentUser = state.data.user.userList.find(user => user.id === userId)!
    const user: IUserDto = { ...currentUser, apiKeys }

    return {
      ...state,
      data: {
        ...state.data,
        user: {
          ...state.data.user,
          inRequest: false,
          inError: false,
          selectedUser: user,
          userList: state.data.user.userList.map(u => (u.id === user.id ? user : u)),
        },
        userError: null,
      },
    }
  }),
  on(ADMIN_ACTIONS.deleteApiKeyError, (state, { error }) => ({
    ...state,
    data: { ...state.data, user: { ...state.data.user, inRequest: false, inError: true, error } },
  })),

  on(ADMIN_ACTIONS.changeSearchUserData, (state, { searchData }) => ({
    ...state,
    data: {
      ...state.data,
      user: {
        ...state.data.user,
        searchPaginationData: searchData,
      },
      userError: null,
    },
  })),

  on(ADMIN_ACTIONS.selectUser, (state, { selectedUser }) => ({
    ...state,
    data: { ...state.data, user: { ...state.data.user, selectedUser } },
  })),

  // Role

  on(ADMIN_ACTIONS.getRoleList, state => ({ ...state, inRequestRole: true })),
  on(ADMIN_ACTIONS.getRoleListSuccess, (state, { roles }) => ({
    ...state,
    inRequestRole: false,
    inErrorRole: false,
    data: { ...state.data, roles, roleError: null },
  })),
  on(ADMIN_ACTIONS.getRoleListError, (state, { error }) => ({
    ...state,
    inRequestRole: false,
    inErrorRole: true,
    data: { ...state.data, roles: [], roleError: error },
  })),

  on(ADMIN_ACTIONS.addRole, state => ({ ...state, inRequestRole: true })),
  on(ADMIN_ACTIONS.addRoleSuccess, (state, { role }) => ({
    ...state,
    inRequestRole: false,
    inErrorRole: false,
    data: { ...state.data, roles: [...state.data.roles, role], error: null },
  })),
  on(ADMIN_ACTIONS.addRoleError, (state, { error }) => ({
    ...state,
    inRequestRole: false,
    inErrorRole: true,
    data: { ...state.data, roleError: error },
  })),

  // Guard Plan

  on(ADMIN_ACTIONS.getGuardPlanList, state => ({
    ...state,
    data: { ...state.data, guard: { ...state.data.guard, inRequest: true } },
  })),
  on(ADMIN_ACTIONS.getGuardPlanListSuccess, (state, { guardPlanList }) => ({
    ...state,
    data: {
      ...state.data,
      guard: { ...state.data.guard, inRequest: false, inError: false, guardPlanList, error: null },
    },
  })),
  on(ADMIN_ACTIONS.getGuardPlanListError, (state, { error }) => ({
    ...state,
    guard: { ...state.data.guard, inRequest: false, inError: true, guardPlanList: [], error },
  })),

  on(ADMIN_ACTIONS.getGuardPlanSectionList, state => ({
    ...state,
    data: { ...state.data, guard: { ...state.data.guard, inRequest: true } },
  })),
  on(ADMIN_ACTIONS.getGuardPlanSectionListSuccess, (state, { guardPlanSectionList }) => ({
    ...state,
    data: {
      ...state.data,
      guard: { ...state.data.guard, inRequest: false, inError: false, guardPlanSectionList, error: null },
    },
  })),
  on(ADMIN_ACTIONS.getGuardPlanSectionListError, (state, { error }) => ({
    ...state,
    guard: { ...state.data.guard, inRequest: false, inError: true, guardPlanSectionList: [], error },
  }))
)

export const adminReducer = (state: IAdminState, action: Action): IAdminState => adminState(state, action)
