import { createFeatureSelector, createSelector } from '@ngrx/store'

import { adminFeatureKey, IAdminState } from './admin.state'

export const selectAdminState = createFeatureSelector<IAdminState>(adminFeatureKey)

export const selectAdminData = createSelector([selectAdminState], state => state.data)

// User

export const selectAdminUser = createSelector([selectAdminData], state => state.user)

export const selectAdminUserInRequest = createSelector([selectAdminUser], state => state.inRequest)

export const selectAdminUserInError = createSelector([selectAdminUser], state => state.inError)

export const selectAdminUsersError = createSelector([selectAdminUser], state => state.error)

export const selectAdminUserList = createSelector([selectAdminUser], state => state.userList)

export const selectAdminUserSearchPaginationData = createSelector(
  [selectAdminUser],
  state => state.searchPaginationData
)

export const selectSelectedUser = createSelector([selectAdminUser], state => state.selectedUser)

// Role

export const selectAdminInRequestRole = createSelector([selectAdminState], state => state.inRequestRole)

export const selectAdminInErrorRole = createSelector([selectAdminState], state => state.inErrorRole)

export const selectAdminRolesList = createSelector([selectAdminData], state => state.roles)

export const selectAdminRolesError = createSelector([selectAdminData], state => state.roleError)

// Guard Plan

export const selectGuard = createSelector([selectAdminData], state => state.guard)

export const selectGuardInRequest = createSelector([selectGuard], state => state.inRequest)

export const selectGuardInError = createSelector([selectGuard], state => state.inError)

export const selectGuardError = createSelector([selectGuard], state => state.error)

export const selectGuardPlanList = createSelector([selectGuard], state => state.guardPlanList)

export const selectGuardPlanSectionList = createSelector([selectGuard], state => state.guardPlanSectionList)
