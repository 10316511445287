export interface IAddlPredDto {
  id: number
  asofDate: string
  beatBothOdds: number
  companyId: number
  corrPred: number
  corrRating: EForecastRisk
  currentFlag: string
  dEpsDRevs: number
  dEpsDRevsRating: EForecastRisk
  epsConsensusBeatOdds: number
  epsMax: number
  epsMin: number
  epsPctile: number
  epsStd: number
  epsStdRating: EForecastRisk
  expectedImpactAtAct: number
  impactAtConsensus: number
  impactAtProxForecast: number
  insertTime: string
  lastUpdateTime: string
  missBothOdds: number
  newSurpRating: number
  proxEpsQ050: number
  proxEpsQ150: number
  proxEpsQ250: number
  proxEpsQ750: number
  proxEpsQ850: number
  proxEpsQ950: number
  proxRevQ050: number
  proxRevQ150: number
  proxRevQ250: number
  proxRevQ750: number
  proxRevQ850: number
  proxRevQ950: number
  qrtrEndDate: string
  revConsensusBeatOdds: number
  revMax: number
  revMin: number
  revPctile: number
  revStd: number
  revStdRating: EForecastRisk
  runId: number
  supersector: string
  surpEpsStd: number
  surpRevStd: number
  version: number
  xLnMktcap: number
}

export enum EForecastRisk {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  VERY_HIGH = 'VERY HIGH',
  EXTREME_UNCERTAINTY = 'EXTREME UNCERTAINTY',
}
