import { Inject, Injectable } from '@angular/core'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators'

import { AdminApiService, GuardApiService, UserApiKeyApiService } from '~/core/api'

import * as UI_ACTIONS from '../ui/ui.actions'

import * as ADMIN_ACTIONS from './admin.actions'
import { ADMIN_FACADE, IAdminFacade } from './admin.facade'

@Injectable()
export class AdminEffects {
  constructor(
    private actions$: Actions,
    private adminApiService: AdminApiService,
    private guardApiService: GuardApiService,
    private userApiKeyApiService: UserApiKeyApiService,
    @Inject(ADMIN_FACADE) private adminFacade: IAdminFacade
  ) {}

  // User

  getUserList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADMIN_ACTIONS.getUserList),
      withLatestFrom(this.adminFacade.searchUserPaginationData$),
      switchMap(([, searchData]) =>
        this.adminApiService.getUserList(searchData).pipe(
          map(userData => ADMIN_ACTIONS.getUserListSuccess({ userData })),
          catchError(error => [
            ADMIN_ACTIONS.getUserListError({ error }),
            UI_ACTIONS.addErrorNotification(error?.message || ''),
          ])
        )
      )
    )
  )

  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADMIN_ACTIONS.createUser),
      mergeMap(({ userData }) =>
        this.adminApiService.createUser(userData).pipe(
          map(user => ADMIN_ACTIONS.createUserSuccess({ user })),
          catchError(error => [
            ADMIN_ACTIONS.createUserError({ error }),
            UI_ACTIONS.addErrorNotification(error?.message || ''),
          ])
        )
      )
    )
  )

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADMIN_ACTIONS.updateUser),
      mergeMap(({ userData }) =>
        this.adminApiService.updateUser(userData).pipe(
          map(user => ADMIN_ACTIONS.updateUserSuccess({ user })),
          catchError(error => [
            ADMIN_ACTIONS.updateUserError({ error }),
            UI_ACTIONS.addErrorNotification(error?.message || ''),
          ])
        )
      )
    )
  )

  banUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADMIN_ACTIONS.banUser),
      mergeMap(({ userData }) =>
        this.adminApiService.banUser(userData).pipe(
          map(user => ADMIN_ACTIONS.banUserSuccess({ user })),
          catchError(error => [
            ADMIN_ACTIONS.banUserError({ error }),
            UI_ACTIONS.addErrorNotification(error?.message || ''),
          ])
        )
      )
    )
  )

  unbanUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADMIN_ACTIONS.unbanUser),
      mergeMap(({ userId }) =>
        this.adminApiService.unbanUser(userId).pipe(
          map(user => ADMIN_ACTIONS.unbanUserSuccess({ user })),
          catchError(error => [
            ADMIN_ACTIONS.unbanUserError({ error }),
            UI_ACTIONS.addErrorNotification(error?.message || ''),
          ])
        )
      )
    )
  )

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADMIN_ACTIONS.deleteUser),
      mergeMap(({ userId }) =>
        this.adminApiService.deleteUser(userId).pipe(
          map(() => ADMIN_ACTIONS.deleteUserSuccess({ userId })),
          catchError(error => [
            ADMIN_ACTIONS.deleteUserError({ error }),
            UI_ACTIONS.addErrorNotification(error?.message || ''),
          ])
        )
      )
    )
  )

  addApiKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADMIN_ACTIONS.addApiKey),
      mergeMap(({ userId }) =>
        this.userApiKeyApiService.addUserApiKey(userId).pipe(
          map(apiKeys => ADMIN_ACTIONS.addApiKeySuccess({ userId, apiKeys })),
          catchError(error => [
            ADMIN_ACTIONS.addApiKeyError({ error }),
            UI_ACTIONS.addErrorNotification(error?.message || ''),
          ])
        )
      )
    )
  )

  deleteApiKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADMIN_ACTIONS.deleteApiKey),
      mergeMap(({ userId, apiKeyIds }) =>
        this.userApiKeyApiService.deleteUserApiKeys(userId, apiKeyIds).pipe(
          map(apiKeys => ADMIN_ACTIONS.deleteApiKeySuccess({ userId, apiKeys })),
          catchError(error => [
            ADMIN_ACTIONS.deleteApiKeyError({ error }),
            UI_ACTIONS.addErrorNotification(error?.message || ''),
          ])
        )
      )
    )
  )

  // Role

  getRoleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADMIN_ACTIONS.getRoleList),
      mergeMap(() =>
        this.adminApiService.getRoleList().pipe(
          map(roles => ADMIN_ACTIONS.getRoleListSuccess({ roles })),
          catchError(error => [
            ADMIN_ACTIONS.getRoleListError({ error }),
            UI_ACTIONS.addErrorNotification(error?.message || ''),
          ])
        )
      )
    )
  )

  addRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADMIN_ACTIONS.addRole),
      mergeMap(({ name }) =>
        this.adminApiService.addRole(name).pipe(
          map(role => ADMIN_ACTIONS.addRoleSuccess({ role })),
          catchError(error => [
            ADMIN_ACTIONS.addRoleError({ error }),
            UI_ACTIONS.addErrorNotification(error?.message || ''),
          ])
        )
      )
    )
  )

  // Guard Plan

  getGuardPlanList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADMIN_ACTIONS.getGuardPlanList),
      mergeMap(() =>
        this.guardApiService.getPlanList().pipe(
          map(({ data: guardPlanList }) => ADMIN_ACTIONS.getGuardPlanListSuccess({ guardPlanList })),
          catchError(error => [
            ADMIN_ACTIONS.getGuardPlanListError({ error }),
            UI_ACTIONS.addErrorNotification(error?.message || ''),
          ])
        )
      )
    )
  )

  getGuardPlanSectionList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADMIN_ACTIONS.getGuardPlanSectionList),
      mergeMap(() =>
        this.guardApiService.getGuardPlanSectionList().pipe(
          map(({ data: guardPlanSectionList }) =>
            ADMIN_ACTIONS.getGuardPlanSectionListSuccess({ guardPlanSectionList })
          ),
          catchError(error => [
            ADMIN_ACTIONS.getGuardPlanSectionListError({ error }),
            UI_ACTIONS.addErrorNotification(error?.message || ''),
          ])
        )
      )
    )
  )
}
