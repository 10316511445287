export interface IChartLineData {
  x: number
  y: number
}

export enum EChartPeriod {
  M1 = 'M1',
  M3 = 'M3',
  M6 = 'M6',
  Y1 = 'Y1',
}
