import { IGuardPlanSection, IPlanDto, IRoleDto, ISearchPaginationData, IUserDto } from '~/core/models'
import { PAGINATION_DATA } from '~const/index'

import { DEFAULT_ORDER } from './admin.constant'

export const adminFeatureKey = 'Admin prox'
export const DEFAULT_USER_PER_PAGE = 50

export interface IAdminState {
  inRequestRole: boolean
  inErrorRole: boolean
  data: {
    user: {
      inRequest: boolean
      inError: boolean
      userList: IUserDto[]
      selectedUser: IUserDto | null
      searchPaginationData: ISearchPaginationData
      error?: Error | null
    }
    roles: IRoleDto[]
    userError?: Error | null
    roleError?: Error | null
    guard: {
      inRequest: boolean
      inError: boolean
      guardPlanList: IPlanDto[]
      guardPlanSectionList: IGuardPlanSection[]
      error?: Error | null
    }
  }
}

export const createAdminInitState = (): IAdminState => ({
  inRequestRole: false,
  inErrorRole: false,
  data: {
    user: {
      inRequest: false,
      inError: false,
      userList: [],
      selectedUser: null,
      searchPaginationData: {
        sortBy: '',
        order: DEFAULT_ORDER,
        filters: {},
        paginatorData: { ...PAGINATION_DATA, perPage: DEFAULT_USER_PER_PAGE },
      },
    },
    roles: [],
    guard: {
      inRequest: false,
      inError: false,
      guardPlanList: [],
      guardPlanSectionList: [],
    },
  },
})

export interface IAppWithAdminState {
  admin: IAdminState
}

export const createAppWithForecastInitState = (): IAppWithAdminState => ({
  admin: createAdminInitState(),
})
