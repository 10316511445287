import { IUserPermissionsDto } from './user.model'

export interface IPlanDto {
  id: number
  name: string
  permissions: IUserPermissionsDto
}

export interface IGuardPlanSectionDto {
  id: number
  name: string
}

export interface IGuardPlanSection {
  id?: number
  guardPlanId: number
  jsonFields: { [key: string]: boolean }
}

export interface IGuardPlanEntity {
  id?: number
  name: string
  guardPlan: IPlanDto
  section: IGuardPlanSectionDto
  isAccess: boolean
  guardPlanId: number
  jsonFields: { [key: string]: boolean }
}

export interface IGuardPlanEntityCreateDto {
  id?: number
  name: string
  sectionId: number
  isAccess: boolean
  guardPlanId: number
  jsonFields: { [key: string]: boolean }
}

export enum EGuardSection {
  FORECAST_INFO = 'FORECAST_INFO',
  FORECAST_INFO_MAIN = 'FORECAST_INFO_MAIN',
  FORECAST_INFO_SCENARIOS = 'FORECAST_INFO_SCENARIOS',
  FORECAST_INFO_HISTORY = 'FORECAST_INFO_HISTORY',
  FORECAST_INFO_COMPARABLE_COMPANIES = 'FORECAST_INFO_COMPARABLE_COMPANIES',
  FORECAST_INFO_CHART_EPS_PDF = 'FORECAST_INFO_CHART_EPS_PDF',
  FORECAST_INFO_CHART_REV_PDF = 'FORECAST_INFO_CHART_REV_PDF',
  FORECAST_INFO_CHART_EPS_CONTOUR = 'FORECAST_INFO_CHART_EPS_CONTOUR',
  FORECAST_INFO_CHART_IMPACT_ISOLINES = 'FORECAST_INFO_CHART_IMPACT_ISOLINES',

  COMPANY_INFO = 'COMPANY_INFO',
  COMPANY_INFO_MAIN = 'COMPANY_INFO_MAIN',
  COMPANY_INFO_SCENARIOS = 'COMPANY_INFO_SCENARIOS',
  COMPANY_INFO_HISTORY = 'COMPANY_INFO_HISTORY',
  COMPANY_INFO_COMPARABLE_COMPANIES = 'COMPANY_INFO_COMPARABLE_COMPANIES',
  COMPANY_INFO_CHART_EPS_PDF = 'COMPANY_INFO_CHART_EPS_PDF',
  COMPANY_INFO_CHART_REV_PDF = 'COMPANY_INFO_CHART_REV_PDF',
  COMPANY_INFO_CHART_EPS_CONTOUR = 'COMPANY_INFO_CHART_EPS_CONTOUR',
  COMPANY_INFO_CHART_IMPACT_ISOLINES = 'COMPANY_INFO_CHART_IMPACT_ISOLINES',
}

export enum EGuardSectionName {
  FORECAST_TABLE = 'FORECAST_TABLE',
  COMPANY_PAGE = 'COMPANY_PAGE',
}

export enum EGuardEntityName {
  FORECAST = 'FORECAST',
  COMPANY = 'COMPANY',
  ADDL_PRED = 'ADDL_PRED',
}
