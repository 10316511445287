import { EGuardEntityName } from './guard.model'

export interface ITableColumn {
  name: string
  key: string
  guardEntityField: string
  guardEntityName: EGuardEntityName
  prefix?: string
  postfix?: string
  i18nName: string
  lgColumn?: boolean
  sticky?: boolean
  stickyEnd?: boolean
  classes?: { [key: string]: boolean }
  isTotalColumn?: boolean
  title?: Function
}

export interface ITableGroupedColumn extends ITableColumn {
  isGrouped?: boolean
}

export interface ITableFilterColumn {
  name: string
  type: ETableFilterColumnType
  colspan: number
  classes?: { [key: string]: boolean }
  sticky?: boolean
  mdSticky?: boolean
  stickyEnd?: boolean
}

export enum ETableFilterColumnType {
  DEFAULT,
  ACTION_SEARCH,
  ACTION_RESET,
  ANNOUNCE_VIEW,
  SELECT,
  STRING,
  NUMBER,
  DATE,
}

export interface ITableFilterSelectField {
  i18nLabel: string
  key: string
  selectValue?: any
  selectOptions: ITableFilterSelectFieldOption[]
}

export interface ITableFilterSelectFieldOption {
  value: any
  i18nTitle?: string
}

export interface ITableAnnounceViewFilter {
  key: string
  i18nTitle: string
  class: string
}
